import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';

import { Spinner } from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import { withRouter } from 'react-router-dom';
import { auth } from '../../helpers/firebase';
import { setUser } from '../../redux/actions';
import {
    validateEmail,
    validatePassword,
    validateString,
} from '../../helpers/validations';
import {
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { successNotification } from '../../helpers/notifications';

const UPDATE_EMAIL = 'UPDATE_EMAIL';
const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

class Profile extends Component {
    state = {
        isLoading: false,
        email: '',
        emailError: '',
        displayName: '',
        displayNameError: '',
        password: '',
        passwordError: '',
        confirmPassword: '',
        confirmPasswordError: '',
        currentPassword: '',
        currentPasswordError: '',
        showCurrentPassword: false,
        pendingUpdate: '',
    };

    componentDidMount() {
        this.setUserOnState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentUser !== this.props.currentUser) {
            this.setUserOnState();
        }
    }

    setUserOnState() {
        const { currentUser } = this.props;

        this.setState({
            displayName: currentUser.displayName,
            email: currentUser.email,
        });
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}Error`]: '',
        });
    };

    handleSubmitEmail = async e => {
        e.preventDefault();
        const { t } = this.props;

        const { displayName, email, currentUser } = this.props;
        const { displayName: _displayName, email: _email } = this.state;

        const displayNameValid = validateString(
            t('PROFILE.PERSONAL_DATA_FORM.NAME'),
            _displayName
        );
        if (displayNameValid) {
            this.setState({ displayNameError: displayNameValid });
            return;
        }

        const emailValid = validateEmail(
            t('PROFILE.PERSONAL_DATA_FORM.EMAIL'),
            _email
        );
        if (emailValid) {
            this.setState({ emailError: emailValid });
            return;
        }

        this.setState({ isLoading: true });

        if (_email !== email) {
            try {
                await auth.currentUser.updateEmail(_email);
            } catch (e) {
                if (e.code === 'auth/requires-recent-login') {
                    this.setState({
                        pendingUpdate: UPDATE_EMAIL,
                        showCurrentPassword: true,
                        isLoading: false,
                    });

                    return;
                }
            }
        }

        if (_displayName !== displayName) {
            await auth.currentUser.updateProfile({
                displayName: _displayName,
            });
        }

        this.props.setUser({
            ...currentUser,
            email: _email,
            displayName: _displayName,
        });

        this.setState(
            {
                isLoading: false,
            },
            () => {
                successNotification(
                    t('PROFILE.SUCCESS_MESSAGE.UPDATED_PERSONAL_DATA')
                );
            }
        );
    };

    handleSubmitPassword = async e => {
        e.preventDefault();
        const { t } = this.props;

        const { password, confirmPassword } = this.state;

        const validPassword = validatePassword(
            t('PROFILE.PASSWORD_FORM.NEW_PASSWORD_INPUT'),
            password
        );
        if (validPassword) {
            this.setState({
                passwordError: validPassword,
            });
            return;
        }

        if (confirmPassword !== password) {
            this.setState({
                confirmPasswordError: t(
                    'PROFILE.PASSWORD_FORM.PASSWORD_DONT_MATCH_ERROR'
                ),
            });
            return;
        }

        this.setState({ isLoading: true });
        auth.currentUser
            .updatePassword(password)
            .then(() => {
                this.setState(
                    {
                        isLoading: false,
                        password: '',
                        confirmPassword: '',
                    },
                    () => {
                        successNotification(
                            t('PROFILE.SUCCESS_MESSAGE.UPDATED_PASSWORD')
                        );
                    }
                );
            })
            .catch(error => {
                if (error.code === 'auth/requires-recent-login') {
                    this.setState({
                        pendingUpdate: UPDATE_PASSWORD,
                        showCurrentPassword: true,
                        isLoading: false,
                    });
                }
            });
    };

    toggleCurrentPassword = () =>
        this.setState({ showCurrentPassword: !this.state.showCurrentPassword });

    continueUpdate = () => {
        const { t } = this.props;
        const { pendingUpdate, currentPassword } = this.state;

        const currentPasswordValid = validatePassword(
            t('PROFILE.PASSWORD_FORM.CURRENT_PASSWORD_INPUT'),
            currentPassword
        );
        if (currentPasswordValid) {
            this.setState({ currentPasswordError: currentPasswordValid });
            return;
        }

        this.setState({ isLoading: true });
        auth.signInWithEmailAndPassword(
            this.props.currentUser.email,
            currentPassword
        )
            .then(() => {
                if (pendingUpdate === UPDATE_EMAIL) {
                    this.handleSubmitEmail({ preventDefault() {} });
                } else {
                    this.handleSubmitPassword({ preventDefault() {} });
                }

                this.setState({
                    isLoading: false,
                    pendingUpdate: '',
                    showCurrentPassword: false,
                });
            })
            .catch(error => {
                if (error.code === 'auth/wrong-password') {
                    this.setState({
                        isLoading: false,
                        currentPasswordError: t(
                            'PROFILE.ERROR_MESSAGE.INVALID_PASSWORD'
                        ),
                    });
                }
            });
    };

    render() {
        const { t, handleLogout } = this.props;

        const {
            isLoading,
            email,
            emailError,
            displayName,
            displayNameError,
            password,
            passwordError,
            confirmPassword,
            confirmPasswordError,
            currentPassword,
            currentPasswordError,
            showCurrentPassword,
        } = this.state;

        return (
            <div className=" section-container">
                {isLoading && <Spinner message="Cargando" />}

                <PageHeader title={t('PROFILE.TITLE')} />

                <div>
                    <h3>{t('PROFILE.PERSONAL_DATA_FORM.TITLE')}</h3>
                    <div className="card card-body">
                        <form
                            onSubmit={this.handleSubmitEmail}
                            noValidate={true}
                        >
                            <FormGroup>
                                <Label for="displayName">
                                    {t('PROFILE.PERSONAL_DATA_FORM.NAME')}
                                </Label>
                                <Input
                                    className="form-control"
                                    id="displayName"
                                    name="displayName"
                                    value={displayName}
                                    onChange={this.handleChange}
                                    invalid={!!displayNameError}
                                />
                                {displayNameError && (
                                    <FormFeedback>
                                        {displayNameError}
                                    </FormFeedback>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">
                                    {t('PROFILE.PERSONAL_DATA_FORM.EMAIL')}
                                </Label>
                                <Input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={this.handleChange}
                                    invalid={!!emailError}
                                />
                                {emailError && (
                                    <FormFeedback>{emailError}</FormFeedback>
                                )}
                            </FormGroup>

                            <button type="submit" className="btn btn-primary">
                                {t('PROFILE.PERSONAL_DATA_FORM.SUBMIT_BUTTON')}
                            </button>
                        </form>
                    </div>
                </div>

                <div className="mt-3">
                    <h3>{t('PROFILE.PASSWORD_FORM.TITLE')}</h3>
                    <div className="card card-body">
                        <form onSubmit={this.handleSubmitPassword}>
                            <FormGroup>
                                <Label for="password">
                                    {t(
                                        'PROFILE.PASSWORD_FORM.NEW_PASSWORD_INPUT'
                                    )}
                                </Label>
                                <Input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={this.handleChange}
                                    invalid={!!passwordError}
                                />
                                {passwordError && (
                                    <FormFeedback>{passwordError}</FormFeedback>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="confirmPassword">
                                    {t(
                                        'PROFILE.PASSWORD_FORM.CONFIRM_PASSWORD_INPUT'
                                    )}
                                </Label>
                                <Input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={this.handleChange}
                                    invalid={!!confirmPasswordError}
                                />
                                {confirmPasswordError && (
                                    <FormFeedback>
                                        {confirmPasswordError}
                                    </FormFeedback>
                                )}
                            </FormGroup>

                            <button type="submit" className="btn btn-primary">
                                {t('PROFILE.PASSWORD_FORM.SUBMIT_BUTTON')}
                            </button>
                        </form>
                    </div>
                </div>

                <MediaQuery maxDeviceWidth={768}>
                    <div className="mt-3">
                        <div className="card card-body">
                            <button
                                className="btn btn-primary"
                                onClick={handleLogout}
                            >
                                {t('PROFILE.LOGOUT_BUTTON')}
                            </button>
                        </div>
                    </div>

                    <div style={{ marginTop: '5rem' }} />
                </MediaQuery>

                <Modal isOpen={showCurrentPassword}>
                    <ModalHeader>
                        {t('PROFILE.CONFIRM_PASSWORD_FORM.TITLE')}
                    </ModalHeader>

                    <ModalBody>
                        <FormGroup>
                            <Label for="currentPassword">
                                {t(
                                    'PROFILE.CONFIRM_PASSWORD_FORM.PASSWORD_INPUT'
                                )}
                            </Label>
                            <Input
                                type="password"
                                id="currentPassword"
                                name="currentPassword"
                                onChange={this.handleChange}
                                value={currentPassword}
                                invalid={!!currentPasswordError}
                            />
                            {currentPasswordError && (
                                <FormFeedback>
                                    {currentPasswordError}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter>
                        <button
                            className="btn btn-dark btn-sm"
                            onClick={this.toggleCurrentPassword}
                        >
                            {t('PROFILE.CONFIRM_PASSWORD_FORM.CANCEL_BUTTON')}
                        </button>

                        <button
                            className="btn btn-primary btn-sm"
                            onClick={this.continueUpdate}
                        >
                            {t('PROFILE.CONFIRM_PASSWORD_FORM.SUBMIT_BUTTON')}
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
});

export default withTranslation()(
    withRouter(
        connect(mapStateToProps, {
            setUser,
        })(Profile)
    )
);
