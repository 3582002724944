import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    FormFeedback,
    FormGroup,
    Row,
    Col,
} from 'reactstrap';
import isDate from 'lodash/isDate';
import cl from 'classnames';
import MediaQuery from 'react-responsive';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { withTranslation } from 'react-i18next';

import {
    firestore,
    serverTimestamp,
    Timestamp,
    fromMillis,
} from '../../helpers/firebase';
import getDateFormatted from '../../helpers/getDateFormatted';
import createDateFromFormatted from '../../helpers/createDateFromFormatted';
import displayDate from '../../helpers/displayDate';
import {
    validateQuantity,
    validateSelect,
    validateString,
} from '../../helpers/validations';
import { Spinner } from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import ConfirmModal from '../../components/ConfirmModal';
import CurrencyInput from '../../components/CurrencyInput';
import CurrencyFormat from '../../components/CurrencyFormat';
import EditButton from '../../components/EditButton';
import DeleteButton from '../../components/DeleteButton';
import { EXPENSE, INCOME } from '../../constants/transaction_types';
import TransactionTypesButtons from '../../components/TransactionTypesButtons';
import { successNotification } from '../../helpers/notifications';

registerLocale('es', es);

const LIMIT = 10;

class Transactions extends Component {
    state = {
        transactionsRef: firestore.collection('transactions'),
        accountsRef: firestore.collection('accounts'),
        isLoading: false,
        openAddTransactionModal: false,
        openDeleteTransactionModal: false,
        transactions: [],
        transaction_id: '',
        date: new Date(),
        description: '',
        value: '',
        account_id: '',
        category_id: '',
        transactionType: EXPENSE,
        descriptionError: '',
        valueError: '',
        account_idError: '',
        category_idError: '',
        dateError: '',
        transactionsEnd: false,
        showFilters: false,
        filterCategoryId: '',
        filterAccountId: '',
        filterTransactionType: '',
    };

    componentDidMount() {
        const { currentUser } = this.props;
        const { transactionsRef } = this.state;

        this.setState({ isLoading: true });
        transactionsRef
            .where('user_id', '==', currentUser.uid)
            .orderBy('date', 'desc')
            .limit(LIMIT)
            .get()
            .then(querySnapshot => {
                const transactions = [];
                querySnapshot.forEach(doc => {
                    transactions.push({
                        id: doc.id,
                        ...doc.data(),
                        date_alt: doc.data().date,
                        date: getDateFormatted(doc.data().date.toDate()),
                        value: parseFloat(doc.data().value),
                    });
                });

                this.setState({
                    transactions,
                    isLoading: false,
                    transactionsEnd: transactions.length < LIMIT,
                });
            });
    }

    loadTransactions = (add = true) => {
        const { currentUser } = this.props;
        const {
            transactionsRef,
            transactions,
            filterCategoryId,
            filterAccountId,
            filterTransactionType,
        } = this.state;
        const lastT = transactions[transactions.length - 1];

        const lastDate = lastT && lastT.date_alt;
        const cursor =
            typeof lastDate === 'number' ? fromMillis(lastDate) : lastDate;

        this.setState({ isLoading: true });
        let query = transactionsRef.where('user_id', '==', currentUser.uid);

        if (filterCategoryId) {
            query = query.where('category_id', '==', filterCategoryId);
        }

        if (filterAccountId) {
            query = query.where('account_id', '==', filterAccountId);
        }

        if (filterTransactionType) {
            const operator = filterTransactionType === INCOME ? '>=' : '<';
            query = query.where('value', operator, 0).orderBy('value');
        }

        query = query.orderBy('date', 'desc');

        if (add && lastT) {
            query = query.startAfter(cursor);
        }

        query
            .limit(LIMIT)
            .get()
            .then(querySnapshot => {
                const newTransactions = [];
                querySnapshot.forEach(doc => {
                    newTransactions.push({
                        id: doc.id,
                        ...doc.data(),
                        date_alt: doc.data().date,
                        date: getDateFormatted(doc.data().date.toDate()),
                        value: parseFloat(doc.data().value),
                    });
                });

                this.setState({
                    transactions: add
                        ? transactions.concat(newTransactions)
                        : newTransactions,
                    isLoading: false,
                    transactionsEnd: newTransactions.length < LIMIT,
                });
            });
    };

    toggleAddTransactionModal = () =>
        this.setState({
            openAddTransactionModal: !this.state.openAddTransactionModal,
            transaction_id: '',
            description: '',
            date: new Date(),
            value: '',
            account_id: '',
            category_id: '',
            transactionType: INCOME,
            descriptionError: '',
            valueError: '',
            account_idError: '',
            category_idError: '',
            dateError: '',
        });

    handleChange = event => {
        const { value, transactionType } = this.state;

        const newState = {
            [event.target.name]: event.target.value,
            [`${event.target.name}Error`]: '',
        };

        if (
            event.target.name === 'transactionType' &&
            event.target.value !== transactionType &&
            value !== ''
        ) {
            newState.value =
                event.target.value === INCOME
                    ? Math.abs(value)
                    : -Math.abs(value);
        }

        if (event.target.name === 'value' && transactionType === EXPENSE) {
            newState.value = -Math.abs(newState.value);
        }

        this.setState(newState);
    };

    setDate = date => this.setState({ date, dateError: '' });

    handleSubmit = e => {
        e.preventDefault();

        const {
            description,
            value,
            account_id,
            category_id,
            transaction_id,
            date,
        } = this.state;

        if (!isDate(date)) {
            this.setState({ dateError: 'La fecha es requerida' });
            return;
        }

        const descriptionValid = validateString('Razón', description);
        if (descriptionValid) {
            this.setState({ descriptionError: descriptionValid });
            return;
        }

        const valueValid = validateQuantity('Monto', value);
        if (valueValid) {
            this.setState({ valueError: valueValid });
            return;
        }

        const account_idValidation = validateSelect('Cuenta', account_id);
        if (account_idValidation) {
            this.setState({ account_idError: account_idValidation });
            return;
        }

        const category_idValidation = validateSelect('Categoria', category_id);
        if (category_idValidation) {
            this.setState({ category_idError: category_idValidation });
            return;
        }

        if (transaction_id === '') {
            this.createTransaction();
            return;
        }

        this.updateTransaction();
    };

    createTransaction = () => {
        const { currentUser, t } = this.props;

        const {
            value,
            description,
            date,
            transactionsRef,
            transactions,
            account_id,
            category_id,
            transactionType,
        } = this.state;

        const newTransaction = {
            user_id: currentUser.uid,
            account_id,
            category_id,
            date: getDateFormatted(date),
            value,
            description,
            transactionType,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        };

        this.setState({ isLoading: true });
        transactionsRef
            .add({ ...newTransaction, date: Timestamp.fromDate(date) })
            .then(response => {
                newTransaction.id = response.id;
                transactions.unshift(newTransaction);
                this.setState(
                    {
                        transactions,
                        isLoading: false,
                        openAddTransactionModal: false,
                        description: '',
                        date: new Date(),
                        value: '',
                        account_id: '',
                        category_id: '',
                        transactionType: INCOME,
                    },
                    () => {
                        successNotification(
                            t(
                                'TRANSACTIONS.SUCCESS_MESSAGE.TRANSACTION_CREATED'
                            )
                        );
                    }
                );
            })
            .catch(error => {
                console.log('ERROR', error);
            });
    };

    updateTransaction = () => {
        const { currentUser, t } = this.props;

        const {
            value,
            description,
            date,
            transaction_id,
            transactionsRef,
            transactions,
            account_id,
            category_id,
            transactionType,
        } = this.state;

        const transaction = {
            user_id: currentUser.uid,
            account_id,
            category_id,
            date: getDateFormatted(date),
            value,
            description,
            transactionType,
            updatedAt: serverTimestamp(),
        };

        transactionsRef
            .doc(transaction_id)
            .update({ ...transaction, date: Timestamp.fromDate(date) })
            .then(() => {
                const tIndex = transactions.findIndex(
                    t => t.id === transaction_id
                );
                transactions[tIndex] = {
                    ...transactions[tIndex],
                    ...transaction,
                };

                this.setState(
                    {
                        transactions,
                        isLoading: false,
                        openAddTransactionModal: false,
                        name: '',
                        description: '',
                        date: new Date(),
                        value: '',
                        account_id: '',
                        category_id: '',
                        transactionType: INCOME,
                    },
                    () => {
                        successNotification(
                            t(
                                'TRANSACTIONS.SUCCESS_MESSAGE.TRANSACTION_UPDATED'
                            )
                        );
                    }
                );
            })
            .catch(error => {
                console.log('ERROR', error);
            });
    };

    editTransaction = transaction => {
        this.setState(state => ({
            ...state,
            ...transaction,
            date: createDateFromFormatted(transaction.date),
            transaction_id: transaction.id,
            openAddTransactionModal: true,
        }));
    };

    toggleDeleteTransactionModal = () =>
        this.setState({
            transaction_id: '',
            description: '',
            openDeleteTransactionModal: !this.state.openDeleteTransactionModal,
            descriptionError: '',
            valueError: '',
            account_idError: '',
            category_idError: '',
            dateError: '',
        });

    toggleShowFilters = () =>
        this.setState({
            showFilters: !this.state.showFilters,
        });

    confirmDeleteTransaction = transaction => {
        this.setState({
            transaction_id: transaction.id,
            account_id: transaction.account_id,
            value: transaction.value,
            description: transaction.description,
            openDeleteTransactionModal: true,
        });
    };

    deleteTransaction = () => {
        const { t } = this.props;
        const {
            transactionsRef,
            transaction_id,
            account_id,
            value,
            accountsRef,
            transactions,
        } = this.state;

        this.setState({ isLoading: true });
        transactionsRef
            .doc(transaction_id)
            .delete()
            .then(async () => {
                const oldAccountRef = accountsRef.doc(account_id);
                const oldAccount = await oldAccountRef.get();

                if (oldAccount.data()) {
                    const oAccount = oldAccount.data();
                    const result = oAccount.value - value;
                    oAccount.value = result < 1 ? 0 : result;
                    await oldAccountRef.set(oAccount);
                }

                const newTransactions = transactions.filter(
                    t => t.id !== transaction_id
                );

                this.setState(
                    {
                        isLoading: false,
                        openDeleteTransactionModal: false,
                        transactions: newTransactions,
                        transaction_id: '',
                        account_id: '',
                        value: '',
                        description: '',
                    },
                    () => {
                        successNotification(
                            t(
                                'TRANSACTIONS.SUCCESS_MESSAGE.TRANSACTION_DELETED'
                            )
                        );
                    }
                );
            });
    };

    render() {
        const { categories, accounts, t } = this.props;

        const {
            isLoading,
            openAddTransactionModal,
            openDeleteTransactionModal,
            transaction_id,
            date,
            description,
            value,
            account_id,
            category_id,
            transactionType,
            descriptionError,
            valueError,
            account_idError,
            category_idError,
            dateError,
            transactions,
            transactionsEnd,
            showFilters,
            filterCategoryId,
            filterAccountId,
            filterTransactionType,
        } = this.state;

        return (
            <div className="section-container">
                {isLoading && <Spinner />}

                <Row>
                    <Col xs={12}>
                        <PageHeader
                            title={t('TRANSACTIONS.TITLE')}
                            handleAdd={this.toggleAddTransactionModal}
                            handleFilter={this.toggleShowFilters}
                        />

                        {showFilters && (
                            <div className="filters card mb-2">
                                <div className="card-body">
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            {/* accounts */}
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    className="form-control"
                                                    name="filterAccountId"
                                                    id="filterAccountId"
                                                    value={filterAccountId}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">
                                                        {t(
                                                            'TRANSACTIONS.FILTERS.ALL_ACCOUNTS_OPTION'
                                                        )}
                                                    </option>
                                                    {accounts.map(account => (
                                                        <option
                                                            key={account.id}
                                                            value={account.id}
                                                        >
                                                            {account.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col xs={12} sm={6}>
                                            {/* cateories */}
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    className="form-control"
                                                    name="filterCategoryId"
                                                    id="filterCategoryId"
                                                    value={filterCategoryId}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">
                                                        {t(
                                                            'TRANSACTIONS.FILTERS.ALL_CATEGORIES_OPTION'
                                                        )}
                                                    </option>
                                                    {categories.map(
                                                        category => (
                                                            <option
                                                                key={
                                                                    category.id
                                                                }
                                                                value={
                                                                    category.id
                                                                }
                                                            >
                                                                {category.name}
                                                            </option>
                                                        )
                                                    )}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} sm={6}>
                                            {/* transactionType */}
                                            <FormGroup>
                                                <Input
                                                    type="select"
                                                    className="form-control"
                                                    name="filterTransactionType"
                                                    id="filterTransactionType"
                                                    value={
                                                        filterTransactionType
                                                    }
                                                    onChange={this.handleChange}
                                                >
                                                    <option value="">
                                                        {t(
                                                            'TRANSACTIONS.FILTERS.ALL_TRANSACTION_TYPES_OPTION'
                                                        )}
                                                    </option>

                                                    <option
                                                        key={INCOME}
                                                        value={INCOME}
                                                    >
                                                        {t(
                                                            'TRANSACTIONS.FILTERS.INCOME_OPTION'
                                                        )}
                                                    </option>

                                                    <option
                                                        key={EXPENSE}
                                                        value={EXPENSE}
                                                    >
                                                        {t(
                                                            'TRANSACTIONS.FILTERS.EXPENSE_OPTION'
                                                        )}
                                                    </option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        {/* <Col xs={12} sm={6}>
                                    date
                                    <FormGroup>
                                        <DatePicker
                                            id="date"
                                            className={cl(
                                                { 'is-invalid': !!dateError },
                                                'form-control'
                                            )}
                                            locale={es}
                                            selected={date}
                                            onChange={this.setDate}
                                            dateFormat="dd/MM/yyyy"
                                            selectsRange
                                        />
                                    </FormGroup>
                                </Col> */}

                                        <Col
                                            xs={12}
                                            sm={6}
                                            className="text-right"
                                        >
                                            <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    this.loadTransactions(false)
                                                }
                                            >
                                                {t(
                                                    'TRANSACTIONS.FILTERS.SUBMIT_BUTTON'
                                                )}
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}

                        <MediaQuery minDeviceWidth={769}>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{t('TRANSACTIONS.TABLE.DATE')}</th>
                                        <th>
                                            {t('TRANSACTIONS.TABLE.ACCOUNT')}
                                        </th>
                                        <th width="50%">
                                            {t(
                                                'TRANSACTIONS.TABLE.DESCRIPTION'
                                            )}
                                        </th>
                                        <th>{t('TRANSACTIONS.TABLE.VALUE')}</th>
                                        <th>
                                            {t('TRANSACTIONS.TABLE.ACTIONS')}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {transactions.map(transaction => (
                                        <tr key={transaction.id}>
                                            <td>
                                                {displayDate(transaction.date)}
                                            </td>
                                            <td>
                                                {
                                                    accounts.find(
                                                        account =>
                                                            account.id ===
                                                            transaction.account_id
                                                    )?.name
                                                }
                                            </td>
                                            <td>{transaction.description}</td>
                                            <td className="text-right">
                                                <CurrencyFormat
                                                    value={transaction.value}
                                                />
                                            </td>
                                            <td>
                                                <div className="btn-group">
                                                    <EditButton
                                                        action={() =>
                                                            this.editTransaction(
                                                                transaction
                                                            )
                                                        }
                                                    />
                                                    <DeleteButton
                                                        action={() =>
                                                            this.confirmDeleteTransaction(
                                                                transaction
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                    {transactionsEnd && (
                                        <tr>
                                            <td
                                                colSpan={5}
                                                className="text-center"
                                            >
                                                {t(
                                                    'TRANSACTIONS.TABLE.EMPTY_STATE'
                                                )}
                                            </td>
                                        </tr>
                                    )}

                                    {!transactionsEnd && (
                                        <tr>
                                            <td
                                                colSpan={5}
                                                className="text-center"
                                            >
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={
                                                        this.loadTransactions
                                                    }
                                                >
                                                    {t(
                                                        'TRANSACTIONS.TABLE.LOAD_MORE'
                                                    )}
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </MediaQuery>

                        <MediaQuery maxDeviceWidth={768}>
                            <Fragment>
                                {transactions.length === 0 && (
                                    <div className="transaction-item">
                                        <p>
                                            {t(
                                                'TRANSACTIONS.TABLE.EMPTY_STATE'
                                            )}
                                        </p>
                                    </div>
                                )}

                                {transactions.map(transaction => (
                                    <div
                                        key={transaction.id}
                                        className="transaction-item"
                                    >
                                        <div className="d-flex justify-content-between">
                                            <p className="date">
                                                {displayDate(transaction.date)}
                                            </p>
                                            <p
                                                className="date"
                                                style={{ fontStyle: 'normal' }}
                                            >
                                                {
                                                    accounts.find(
                                                        account =>
                                                            account.id ===
                                                            transaction.account_id
                                                    )?.name
                                                }
                                            </p>
                                        </div>
                                        <p className="description">
                                            {transaction.description}
                                        </p>
                                        <p className="value">
                                            <CurrencyFormat
                                                value={transaction.value}
                                            />
                                        </p>
                                        <div className="btn-group">
                                            <EditButton
                                                action={() =>
                                                    this.editTransaction(
                                                        transaction
                                                    )
                                                }
                                            />
                                            <DeleteButton
                                                action={() =>
                                                    this.confirmDeleteTransaction(
                                                        transaction
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}

                                {!transactionsEnd && (
                                    <div className="text-center">
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.loadTransactions}
                                        >
                                            {t('TRANSACTIONS.TABLE.LOAD_MORE')}
                                        </button>
                                    </div>
                                )}

                                <div style={{ marginTop: '5rem' }} />
                            </Fragment>
                        </MediaQuery>

                        <Modal
                            isOpen={openAddTransactionModal}
                            scrollable={true}
                        >
                            <form onSubmit={this.handleSubmit}>
                                <ModalHeader>
                                    {transaction_id
                                        ? t('TRANSACTIONS.FORM.EDIT_TITLE')
                                        : t('TRANSACTIONS.FORM.ADD_TITLE')}
                                </ModalHeader>

                                <ModalBody>
                                    <FormGroup>
                                        <Label for="date">
                                            {t('TRANSACTIONS.FORM.DATE_INPUT')}
                                        </Label>

                                        <DatePicker
                                            id="date"
                                            className={cl(
                                                { 'is-invalid': !!dateError },
                                                'form-control'
                                            )}
                                            locale={es}
                                            selected={date}
                                            onChange={this.setDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {!!dateError && (
                                            <div
                                                className="invalid-feedback"
                                                style={{ display: 'block' }}
                                            >
                                                {dateError}
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="description">
                                            {t(
                                                'TRANSACTIONS.FORM.DESCRIPTION_INPUT'
                                            )}
                                        </Label>
                                        <Input
                                            className="form-control"
                                            name="description"
                                            id="description"
                                            value={description}
                                            onChange={this.handleChange}
                                            invalid={!!descriptionError}
                                        />
                                        {!!descriptionError && (
                                            <FormFeedback>
                                                {descriptionError}
                                            </FormFeedback>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="value">
                                            {t('TRANSACTIONS.FORM.VALUE_INPUT')}
                                        </Label>
                                        <CurrencyInput
                                            classes={cl(
                                                { 'is-invalid': !!valueError },
                                                'form-control'
                                            )}
                                            name="value"
                                            id="value"
                                            value={value}
                                            onChange={this.handleChange}
                                        />
                                        {!!valueError && (
                                            <div className="invalid-feedback">
                                                {valueError}
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <TransactionTypesButtons
                                            typeActive={transactionType}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="account_id">
                                            {t(
                                                'TRANSACTIONS.FORM.ACCOUNT_INPUT'
                                            )}
                                        </Label>
                                        <Input
                                            type="select"
                                            className="form-control"
                                            name="account_id"
                                            id="account_id"
                                            value={account_id}
                                            onChange={this.handleChange}
                                            invalid={!!account_idError}
                                        >
                                            <option value="" />
                                            {accounts.map(account => (
                                                <option
                                                    key={account.id}
                                                    value={account.id}
                                                >
                                                    {account.name}
                                                </option>
                                            ))}
                                        </Input>
                                        {!!account_idError && (
                                            <FormFeedback>
                                                {account_idError}
                                            </FormFeedback>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="category_id">
                                            {t(
                                                'TRANSACTIONS.FORM.CATEGORY_INPUT'
                                            )}
                                        </Label>
                                        <Input
                                            type="select"
                                            className="form-control"
                                            name="category_id"
                                            id="category_id"
                                            value={category_id}
                                            onChange={this.handleChange}
                                            invalid={!!category_idError}
                                        >
                                            <option value="" />
                                            {categories
                                                .filter(
                                                    account =>
                                                        account.transactionType ===
                                                        transactionType
                                                )
                                                .map(category => (
                                                    <option
                                                        key={category.id}
                                                        value={category.id}
                                                    >
                                                        {category.name}
                                                    </option>
                                                ))}
                                        </Input>
                                        {!!category_idError && (
                                            <FormFeedback>
                                                {category_idError}
                                            </FormFeedback>
                                        )}
                                    </FormGroup>
                                </ModalBody>

                                <ModalFooter>
                                    <button
                                        type="button"
                                        className="btn btn-dark btn-sm"
                                        onClick={this.toggleAddTransactionModal}
                                    >
                                        {t('TRANSACTIONS.FORM.CANCEL_BUTTON')}
                                    </button>

                                    <button
                                        className="btn btn-primary btn-sm"
                                        type="submit"
                                    >
                                        {t('TRANSACTIONS.FORM.SUBMIT_BUTTON')}
                                    </button>
                                </ModalFooter>
                            </form>
                        </Modal>

                        <ConfirmModal
                            open={openDeleteTransactionModal}
                            handleCancel={this.toggleDeleteTransactionModal}
                            handleSuccess={this.deleteTransaction}
                            message={`${t(
                                'TRANSACTIONS.FORM.CONFIRM_DELETE_MESSAGE'
                            )} ${description}?`}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    accounts: state.catalogs.accounts,
    categories: state.catalogs.categories,
});

export default withTranslation()(
    withRouter(connect(mapStateToProps)(Transactions))
);
