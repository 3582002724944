import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    FormGroup,
    FormFeedback,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';

import { firestore } from '../../helpers/firebase';
import {
    addCategory,
    removeCategory,
    updateCategory,
} from '../../redux/catalogs/actions';
import { validateString } from '../../helpers/validations';
import { Spinner } from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import ConfirmModal from '../../components/ConfirmModal';
import EditButton from '../../components/EditButton';
import DeleteButton from '../../components/DeleteButton';
import TransactionTypesButtons from '../../components/TransactionTypesButtons';
import { EXPENSE, INCOME } from '../../constants/transaction_types';
import { successNotification } from '../../helpers/notifications';

class Categories extends Component {
    state = {
        category_id: '',
        name: '',
        description: '',
        nameError: '',
        transactionType: EXPENSE,
        isLoading: false,
        openAddCategoryModal: false,
        openDeleteCategoryModal: false,
        categoriesRef: firestore.collection('categories'),
    };

    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}Error`]: '',
        });
    };

    toggleAddCategoryModal = () =>
        this.setState({
            category_id: '',
            name: '',
            description: '',
            nameError: '',
            openAddCategoryModal: !this.state.openAddCategoryModal,
        });

    toggleDeleteCategoryModal = () =>
        this.setState({
            category_id: '',
            openDeleteCategoryModal: !this.state.openDeleteCategoryModal,
        });

    handleSubmit = e => {
        e.preventDefault();

        const { name } = this.state;

        const valid = validateString('Nombre', name);

        if (valid) {
            this.setState({ nameError: valid });
            return;
        }

        if (this.state.category_id === '') {
            this.createCategory();
            return;
        }

        this.updateCategory();
    };

    createCategory = () => {
        const { currentUser, addCategory, t } = this.props;
        const { categoriesRef, name, description, transactionType } =
            this.state;

        const newCategory = {
            name,
            description,
            transactionType,
            user_id: currentUser.uid,
        };

        this.setState({ isLoading: true });
        categoriesRef
            .add(newCategory)
            .then(response => {
                newCategory.id = response.id;
                addCategory(newCategory);

                this.setState(
                    {
                        isLoading: false,
                        openAddCategoryModal: false,
                        name: '',
                        description: '',
                        nameError: '',
                    },
                    () => {
                        successNotification(
                            t('CATEGORIES.SUCCESS_MESSAGE.CREATED')
                        );
                    }
                );
            })
            .catch(error => {
                console.log('ERROR', error);
                this.setState({ isLoading: false });
            });
    };

    editCategory = category => {
        this.setState({
            name: category.name,
            nameError: '',
            description: category.description,
            transactionType: category.transactionType,
            category_id: category.id,
            openAddCategoryModal: true,
        });
    };

    updateCategory = () => {
        const { currentUser, updateCategory, t } = this.props;
        const {
            categoriesRef,
            category_id,
            name,
            description,
            transactionType,
        } = this.state;

        const catUpdated = {
            name,
            description,
            transactionType,
            user_id: currentUser.uid,
        };
        this.setState({ isLoading: true });
        categoriesRef
            .doc(category_id)
            .set(catUpdated)
            .then(() => {
                updateCategory(category_id, { ...catUpdated, id: category_id });

                this.setState(
                    {
                        openAddCategoryModal: false,
                        isLoading: false,
                        category_id: '',
                        name: '',
                        description: '',
                        nameError: '',
                    },
                    () => {
                        successNotification(
                            t('CATEGORIES.SUCCESS_MESSAGE.UPDATED')
                        );
                    }
                );
            });
    };

    confirmDeleteCategory = category => {
        this.setState({
            category_id: category.id,
            name: category.name,
            openDeleteCategoryModal: true,
        });
    };

    deleteCategory = () => {
        const { removeCategory, t } = this.props;
        const { categoriesRef, category_id } = this.state;

        this.setState({ isLoading: true });
        categoriesRef
            .doc(category_id)
            .delete()
            .then(() => {
                removeCategory(category_id);

                this.setState(
                    {
                        isLoading: false,
                        category_id: '',
                        name: '',
                        openDeleteCategoryModal: false,
                    },
                    () => {
                        successNotification(
                            t('CATEGORIES.SUCCESS_MESSAGE.DELETED')
                        );
                    }
                );
            });
    };

    render() {
        const { categories, t } = this.props;

        const {
            isLoading,
            openAddCategoryModal,
            openDeleteCategoryModal,
            category_id,
            name,
            description,
            transactionType,
            nameError,
        } = this.state;

        return (
            <div className="section-container">
                {isLoading && <Spinner message="Cargando" />}

                <PageHeader
                    title={t('CATEGORIES.TITLE')}
                    handleAdd={this.toggleAddCategoryModal}
                />

                <MediaQuery minDeviceWidth={769}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>{t('CATEGORIES.TABLE.NAME')}</th>
                                <th>{t('CATEGORIES.TABLE.TYPE')}</th>
                                <th>{t('CATEGORIES.TABLE.DESCRIPTION')}</th>
                                <th>{t('CATEGORIES.TABLE.ACTIONS')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {categories.length === 0 && (
                                <tr>
                                    <td colSpan={4} className="text-center">
                                        {t('CATEGORIES.TABLE.EMPTY_STATE')}
                                    </td>
                                </tr>
                            )}

                            {categories.map(category => (
                                <tr key={category.id}>
                                    <td>{category.name}</td>
                                    <td className="text-center">
                                        {category.transactionType ===
                                        'EXPENSE' ? (
                                            <span className="badge badge-danger">
                                                {t(
                                                    'CATEGORIES.TABLE.EXPENSE_OPTION'
                                                )}
                                            </span>
                                        ) : (
                                            <span className="badge badge-success">
                                                {t(
                                                    'CATEGORIES.TABLE.INCOME_OPTION'
                                                )}
                                            </span>
                                        )}
                                    </td>
                                    <td>{category.description}</td>
                                    <td>
                                        <div className="btn-group">
                                            <EditButton
                                                action={() =>
                                                    this.editCategory(category)
                                                }
                                            />
                                            <DeleteButton
                                                action={() =>
                                                    this.confirmDeleteCategory(
                                                        category
                                                    )
                                                }
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </MediaQuery>

                <MediaQuery maxDeviceWidth={768}>
                    {categories.length === 0 && (
                        <div className="category-item">
                            <p>{t('CATEGORIES.TABLE.EMPTY_STATE')}</p>
                        </div>
                    )}
                    {categories.map(category => (
                        <div key={category.id} className="category-item">
                            <div className="d-flex">
                                <div className="description flex-grow-1">
                                    <p className="name">{category.name}</p>
                                    <p className="description">
                                        {category.description}
                                    </p>
                                </div>
                                <div className="icon">
                                    {category.transactionType === INCOME ? (
                                        <FaArrowUp
                                            size={30}
                                            className="text-success"
                                        />
                                    ) : (
                                        <FaArrowDown
                                            size={30}
                                            className="text-danger"
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="btn-group">
                                    <EditButton
                                        action={() =>
                                            this.editCategory(category)
                                        }
                                    />
                                    <DeleteButton
                                        action={() =>
                                            this.confirmDeleteCategory(category)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <div style={{ marginTop: '5rem' }} />
                </MediaQuery>

                <Modal isOpen={openAddCategoryModal}>
                    <form onSubmit={this.handleSubmit}>
                        <ModalHeader>
                            {category_id
                                ? t('CATEGORIES.FORM.EDIT_TITLE')
                                : t('CATEGORIES.FORM.ADD_TITLE')}
                        </ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label for="name">
                                    {t('CATEGORIES.FORM.NAME')}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    value={name}
                                    onChange={this.handleChange}
                                    invalid={!!nameError}
                                />
                                {!!nameError && (
                                    <FormFeedback>{nameError}</FormFeedback>
                                )}
                            </FormGroup>

                            <FormGroup>
                                <Label for="description">
                                    {t('CATEGORIES.FORM.DESCRIPTION')}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={description}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>

                            <FormGroup>
                                <TransactionTypesButtons
                                    typeActive={transactionType}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </ModalBody>

                        <ModalFooter>
                            <button
                                className="btn btn-dark"
                                onClick={this.toggleAddCategoryModal}
                            >
                                {t('CATEGORIES.FORM.CANCEL_BUTTON')}
                            </button>

                            <button type="submit" className="btn btn-primary">
                                {t('CATEGORIES.FORM.SUBMIT_BUTTON')}
                            </button>
                        </ModalFooter>
                    </form>
                </Modal>

                <ConfirmModal
                    open={openDeleteCategoryModal}
                    handleCancel={this.toggleDeleteCategoryModal}
                    handleSuccess={this.deleteCategory}
                    message={`${t(
                        'CATEGORIES.FORM.CONFIRM_DELETE_MESSAGE'
                    )} ${name}?`}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    categories: state.catalogs.categories,
});

export default withTranslation()(
    withRouter(
        connect(mapStateToProps, {
            updateCategory,
            removeCategory,
            addCategory,
        })(Categories)
    )
);
