import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import './App.css';
import * as ROUTES from '../../constants/routes';
import { loadCatalogs, setAccounts } from '../../redux/catalogs/actions';
import Navbar from '../../components/Navbar';
import Accounts from './Accounts';
import Profile from './Profile';
import Transactions from './Transactions';
import Categories from './Categories';
import { Spinner } from '../../components/Spinner';
import { LOADING } from '../../constants/loadStatus';
import ResponsiveNavbar from '../../components/ResponsiveNavbar';
import { auth, firestore } from '../../helpers/firebase';
import collectIdsAndDocs from '../../helpers/collectIdsAndDocs';
import Reports from './Reports';

class App extends Component {
    state = {
        navBarOpen: false,
    };

    accountsRef = firestore.collection('accounts');

    unsubscribe = null;

    componentDidMount() {
        const { currentUser, loadCatalogs, setAccounts } = this.props;

        loadCatalogs(currentUser.uid);

        this.unsubscribe = this.accountsRef
            .where('user_id', '==', currentUser.uid)
            .onSnapshot(snapshot => {
                const accounts = snapshot.docs.map(collectIdsAndDocs);
                setAccounts(accounts);
            });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    toggleNavBar = () => this.setState({ navBarOpen: !this.state.navBarOpen });

    handleLogout = () => {
        auth.signOut();

        this.props.history.push(ROUTES.LOGIN);
    };

    render() {
        const { loadingStatus, history } = this.props;

        const { navBarOpen } = this.state;

        return (
            <Fragment>
                <ToastContainer />

                {loadingStatus === LOADING && <Spinner />}

                <MediaQuery minDeviceWidth={769}>
                    <Navbar
                        activeItem={history.location.pathname}
                        navBarOpen={navBarOpen}
                        toggleNavBar={this.toggleNavBar}
                        handleLogout={this.handleLogout}
                    />
                </MediaQuery>

                <div className="container flex-grow-1">
                    <Switch>
                        <Route
                            path={ROUTES.PROFILE}
                            component={props => (
                                <Profile
                                    {...props}
                                    handleLogout={this.handleLogout}
                                />
                            )}
                            exact
                        />
                        <Route
                            path={ROUTES.CATEGORIES}
                            component={Categories}
                            exact
                        />
                        <Route
                            path={ROUTES.TRANSACTIONS}
                            component={Transactions}
                            exact
                        />
                        <Route
                            component={Accounts}
                            path={ROUTES.ACCOUNTS}
                            exact
                        />
                        <Route
                            component={Reports}
                            path={ROUTES.REPORTS}
                            exact
                        />
                    </Switch>
                </div>

                <MediaQuery maxDeviceWidth={768}>
                    <ResponsiveNavbar
                        handleLogout={this.handleLogout}
                        currentLocation={history.location.pathname}
                    />
                </MediaQuery>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    loadingStatus: state.catalogs.loadingStatus,
});

export default withRouter(
    connect(mapStateToProps, { loadCatalogs, setAccounts })(App)
);
