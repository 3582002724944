import { NavLink } from 'react-router-dom';
import {
    FaChartBar,
    FaWallet,
    FaListAlt,
    FaTags,
    FaUserAlt,
} from 'react-icons/fa';
import { withTranslation } from 'react-i18next';

import './ResponsiveNavbar.scss';
import * as ROUTES from '../constants/routes';

const ResponsiveNavbar = ({ currentLocation, t }) => {
    const getColor = route => {
        return currentLocation === route ? '#fff' : '#e4e4e4e4';
    };

    return (
        <div className="ResponsiveNavbar-container">
            <NavLink to={ROUTES.ACCOUNTS}>
                <FaWallet color={getColor(ROUTES.ACCOUNTS)} size={25} />
                <span style={{ color: getColor(ROUTES.PROFILE) }}>
                    {t('ACCOUNTS.TITLE')}
                </span>
            </NavLink>

            <NavLink to={ROUTES.TRANSACTIONS}>
                <FaListAlt color={getColor(ROUTES.TRANSACTIONS)} size={25} />
                <span style={{ color: getColor(ROUTES.PROFILE) }}>
                    {t('TRANSACTIONS.TITLE').slice(0, 7)}.
                </span>
            </NavLink>

            <NavLink to={ROUTES.CATEGORIES}>
                <FaTags color={getColor(ROUTES.CATEGORIES)} size={25} />
                <span style={{ color: getColor(ROUTES.PROFILE) }}>
                    {t('CATEGORIES.TITLE')}
                </span>
            </NavLink>

            <NavLink to={ROUTES.REPORTS}>
                <FaChartBar color={getColor(ROUTES.REPORTS)} size={25} />
                <span style={{ color: getColor(ROUTES.PROFILE) }}>
                    {t('REPORTS.TITLE')}
                </span>
            </NavLink>

            <NavLink to={ROUTES.PROFILE}>
                <FaUserAlt color={getColor(ROUTES.PROFILE)} size={25} />
                <span style={{ color: getColor(ROUTES.PROFILE) }}>
                    {t('PROFILE.TITLE')}
                </span>
            </NavLink>
        </div>
    );
};

export default withTranslation()(ResponsiveNavbar);
